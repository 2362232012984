.inputs-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.progress-container {
    margin-top: 10px;
    width: 50vh;
}
.component-container {
    padding: 10px 0;
}